<template>
  <div class="page-layout details member-details" title="">

    <HeaderIntroImage :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container" v-if="!memberData">
      <br>
      <h3>Loading Data ...</h3>
      <br>
    </div>

    <div class="container" v-if="memberData">
      <div class="flex-member">

        <aside class="col1">
          <div class="member--thumbnail">
            <ImageItem v-if="memberData.field_photo" :imagedata="memberData" fieldname="field_photo"
              :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
            </ImageItem>
            <picture v-else>
              <img src="../../assets/profile.jpg" alt="">
            </picture>
          </div>
        </aside>

        <section class="col2_1">
          <p class="from label" v-if="memberData.field_organization[0].attributes.field_country">{{ country(0) }}</p>
          
          <div class="name-col">
            <h1 class="name">{{ memberData.attributes.title }}</h1>
            <div class="awards">
              <div v-if="(this.contribMaterial.length + this.contribPCT.length) > 0" class="projects"> 
                <img src="../../assets/icon_awardproject_yellow.svg" alt=""> 
              </div>
              <div v-if="this.projectData.length" class="contribs"> 
                <img src="../../assets/icon_awardmember_yellow.svg" alt=""> 
              </div>
            </div>
          </div>

          <div v-for="(j, index) in memberData.field_organization" :key="'job'+index">

            <div v-if="j.attributes.field_org_name" class="job icon-left work">

              <p class="blue">{{ j.attributes.field_org_position }}</p>

              <p class="label bold" style="display:inline-block"> 
                {{ 
                  (j.attributes.field_org_name ? j.attributes.field_org_name : '') + 
                  (((j.attributes.field_org_name && j.attributes.field_city) || (j.attributes.field_org_name && j.attributes.field_country)) ? ',' : '')
                }}
              </p>

              <p class="label" style="display:inline-block" v-if="j.attributes.field_city">
                {{ 
                  j.attributes.field_city +
                  (j.attributes.field_country ? ', ' : '')
                }}
              </p>

              <p class="label" style="display:inline-block" v-if="j.attributes.field_country">
                {{ country(index) }}
              </p>

            </div>
          </div>
        </section>

        <aside class="col3">
          <div class="contacts-aside">
            <div class="social-links">
              <a class="social plain" v-if="memberData.attributes.field_linkedin" target="_blank"
                :href="memberData.attributes.field_linkedin.uri">
                <img src="../../assets/icon-linkedin-profile.svg" alt="">
              </a>
              <a class="social plain" v-if="memberData.attributes.field_twitter" target="_blank"
                :href="memberData.attributes.field_twitter.uri">
                <img src="../../assets/icon-twitter-profile.svg" alt="">
              </a>
              <a class="social plain" v-if="memberData.attributes.field_personal_website" target="_blank"
                :href="memberData.attributes.field_personal_website.uri">
                <img src="../../assets/icon-website-profile.svg" alt="">
              </a>
            </div>
            <p class="icon-right email"><a class="email plain dark" :href="'mailto:'+ memberData.uid.attributes.display_name">{{ memberData.uid.attributes.display_name }}</a></p>
            <p v-for="(email, index) in memberData.attributes.field_email" :key="'email'+index"
              class="icon-right email"><a class="email plain dark" :href="'mailto:'+email">{{ email }}</a></p>
            <p v-for="(phone, index) in memberData.attributes.field_phone" :key="'phone'+index" class="icon-right tel">
              {{ phone }}</p>
          </div>
        </aside>

      </div>

      <div class="grid-member">
        <div class="col1"></div>
        <div class="col2_1"></div>

        <section class="col2_2">
          <div class="gray-tiles">
            <div class="wps" v-if="wgData.length>0">
              <p class="label">ACTIVE IN WORKING GROUPS:</p>
              <div :class="['icon-left', 'target'+wg.attributes.drupal_internal__nid]"
                v-for="(wg, index) in this.wgData" :key="'wg'+index">
                <router-link :to="wglink(wg)" class="bold blue icon-right arrow plain">
                  <span>{{ wg.attributes.title }}</span>
                </router-link>
              </div>
            </div>

            <div class="pilots" v-if="projectData.length>0">
              <p class="label icon-left pilot">INVOLVED IN PROJECT PILOT:</p>
              <div v-for="(pj, index) in this.projectData" :key="'pj'+index">
                <router-link :to="projectlink(pj)" class="bold icon-right arrow pilpj plain">
                  <span>{{ pj.attributes.title }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </section>

        <div class="col3"></div>

      </div>
    </div>

    <div class="fullwidth blue-bg" style="margin-top: 0;" v-if="memberData
      && ((memberData.field_programs_and_initiatives && memberData.field_programs_and_initiatives.length)
        || (memberData.attributes.field_topics_of_work && memberData.attributes.field_topics_of_work.length)) 
      && (memberData.field_programs_and_initiatives[0].field_program_name 
        || memberData.field_programs_and_initiatives[0].field_program_url)">

      <div class="container" v-if="memberData.attributes.field_topics_of_work">
        <h3>Topics of work & research</h3>
        <p class="list-1" v-html="memberData.attributes.field_topics_of_work.value"></p>
      </div>

      <div class="container"
        v-if="memberData.field_programs_and_initiatives && memberData.field_programs_and_initiatives.length ">
        <h3>Education Programs & Initiatives involved in</h3>
        <div class="objective" v-for="(pi, index) in memberData.field_programs_and_initiatives" :key="'prin'+index">

          <div v-if="pi.attributes.field_program_url || pi.attributes.field_program_name"
            :class="['bold col1 icon-left', pi.attributes.field_program_url ? 'external' : 'bullet']">

            <a target="_blank" v-if="pi.attributes.field_program_url && pi.attributes.field_program_name"
              :href="pi.attributes.field_program_url.uri">{{ pi.attributes.field_program_name }}</a>
            <a target="_blank" v-if="pi.attributes.field_program_url && !pi.attributes.field_program_name"
              :href="pi.attributes.field_program_url.uri">{{ pi.attributes.field_program_url.uri }}</a>
            <p class="pink" v-if="!pi.attributes.field_program_url && pi.attributes.field_program_name">
              {{ pi.attributes.field_program_name }}</p>

          </div>

          <div class="col2" v-if="pi.attributes.field_program_description"
            v-html="pi.attributes.field_program_description.value"></div>
        </div>

      </div>
    </div>

    <div class="container contributions" v-if="contribMaterial.length || contribPCT.length">
      <h2>My Contributions to QTEdu</h2>
      
      <BlockCarousel class="contributions">
        <router-link :to="link('material', m.attributes.drupal_internal__nid)" v-for="(m, ind) in this.contribMaterial"
          :key="'mat_contrib_'+ind" class="contribution plain">
          <GrayCard>
            <p class="label blue">MATERIAL</p>
            <p>{{ m.attributes.title }}</p>
            <p class="icon-left arrow"></p>
          </GrayCard>
        </router-link>

        <router-link :to="link('course', m.attributes.drupal_internal__nid)" v-for="(m, ind) in this.contribPCT"
          :key="'pct_contrib_'+ind" class="contribution plain">
          <GrayCard>
            <p class="label blue">PROGRAM</p>
            <p>{{ m.attributes.title }}</p>
            <p class="icon-left arrow"></p>
          </GrayCard>
        </router-link>
      </BlockCarousel>
    </div>

    <div class="container" v-if="memberData">
      <div v-if="memberData.field_collaboration_objective && memberData.field_collaboration_objective.length">
        <h2>My Collaboration objectives</h2>
        <div class="objective">
          <div class="col1">
            <keep-alive v-for="(obj, index) in memberData.field_collaboration_objective" :key="'obj'+index">
              <div class="icon-left bullet" v-if="obj.attributes.name!='Other (specify)'">
                <p class="label bold blue">{{ obj.attributes.name }}</p>
              </div>
            </keep-alive>
          </div>
          <div class="col2">
            <p v-if="memberData.attributes.field_collaboration_spec"
              v-html="memberData.attributes.field_collaboration_spec.value"></p>
          </div>
        </div>
      </div>
      <div v-if="objectiveMatches.length && loggedUser && loggedIsMember">
        <p class="label">Suggested contacts</p>
        <BlockCarousel infinite dotted>
          <MemberCardMin v-for="(m, ind) in objectiveMatches" :key="'competencematch'+ind" :memberData="m"
            :routesData="routesData" />
        </BlockCarousel>
      </div>

    </div>

    <div class="fullwidth gray-bg" v-if="memberData &&
      ((memberData.field_competences_looking_for && memberData.field_competences_looking_for.length)
      || (memberData.field_resource_looking_for && memberData.field_resource_looking_for.length)
      || (memberData.field_resource_offer && memberData.field_resource_offer.length)
      || (memberData.field_competences_offer && memberData.field_competences_offer.length))">

      <div class="container">
        <h2 v-if="
        (memberData.field_competences_looking_for && memberData.field_competences_looking_for.length)
        || (memberData.field_resource_looking_for && memberData.field_resource_looking_for.length)">
          Looking for
        </h2>

        <div v-if="(memberData.field_competences_looking_for && memberData.field_competences_looking_for.length)">
          <h4>Competences</h4>
          <div class="objective">
            <div class="col1">
              <keep-alive v-for="(obj, index) in memberData.field_competences_looking_for" :key="'lf_comp'+index">
                <div class="icon-left bullet" v-if="obj.attributes.name!='Other (specify)'">
                  <p class="label bold blue">{{ obj.attributes.name }}</p>
                </div>
              </keep-alive>
            </div>
            <div class="col2">
              <div class="col2">
                <p v-if="memberData.attributes.field_competences_lf_specific"
                  v-html="memberData.attributes.field_competences_lf_specific.value"></p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="competenceMatches.length && loggedUser && loggedIsMember">
          <p class="label">Suggested contacts</p>
          <BlockCarousel infinite dotted>
            <MemberCardMin v-for="(m, ind) in competenceMatches" :key="'competencematch'+ind" :memberData="m"
              :routesData="routesData" />
          </BlockCarousel>
        </div>

        <div v-if="(memberData.field_resource_looking_for && memberData.field_resource_looking_for.length)">
          <h4>Resources</h4>
          <div class="objective">
            <div class="col1">
              <keep-alive v-for="(obj, index) in memberData.field_resource_looking_for" :key="'lf_res'+index">
                <div class="icon-left bullet" v-if="obj.attributes.name!='Other (specify)'">
                  <p class="label bold blue">{{ obj.attributes.name }}</p>
                </div>
              </keep-alive>
            </div>
            <div class="col2">
              <p v-if="memberData.attributes.field_resource_lf_specific"
                v-html="memberData.attributes.field_resource_lf_specific.value"></p>
            </div>
          </div>
        </div>
        <div v-if="resourceMatches.length && loggedUser && loggedIsMember">
          <p class="label">Suggested contacts</p>
          <BlockCarousel infinite dotted>
            <MemberCardMin v-for="(m, ind) in resourceMatches" :key="'resourcematch'+ind" :memberData="m"
              :routesData="routesData" />
          </BlockCarousel>
        </div>

        <br>

        <h2 v-if="
        (memberData.field_resource_offer && memberData.field_resource_offer.length)
        || (memberData.field_competence_offer && memberData.field_competence_offer.length)">
          What I offer
        </h2>

        <div v-if="(memberData.field_competence_offer && memberData.field_competence_offer.length)">
          <h4>Competences</h4>
          <div class="objective">
            <div class="col1">
              <keep-alive v-for="(obj, index) in memberData.field_competence_offer" :key="'off_comp'+index">
                <div class="icon-left bullet" v-if="obj.attributes.name!='Other (specify)'">
                  <p class="label bold blue">{{ obj.attributes.name }}</p>
                </div>
              </keep-alive>
            </div>
            <div class="col2">
              <p v-if="memberData.attributes.field_competences_specification"
                v-html="memberData.attributes.field_competences_specification.value"></p>
            </div>
          </div>
        </div>

        <div v-if="(memberData.field_resource_offer && memberData.field_resource_offer.length)">
          <h4>Resources</h4>
          <div class="objective">
            <div class="col1">
              <keep-alive v-for="(obj, index) in memberData.field_resource_offer" :key="'off_res'+index">
                <div class="icon-left bullet" v-if="obj.attributes.name!='Other (specify)'">
                  <p class="label bold blue">{{ obj.attributes.name }}</p>
                </div>
              </keep-alive>
            </div>
            <div class="col2">
              <p v-if="memberData.attributes.field_resources_offer_specificat"
                v-html="memberData.attributes.field_resources_offer_specificat.value"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
  import GrayCard from '../../components/atomic-elements/GrayCard.vue'
  import ImageItem from '../../components/atomic-elements/ImageItem.vue'
  import BlockCarousel from '../../components/layout-blocks/BlockCarousel.vue'
  import MemberCardMin from '../../components/layout-blocks/MemberCardMin.vue'
  import {
    fetchNodes
  } from '../../libs/drupalClient'
  import {
    shuffleArray
  } from '../../libs/utils'
  export default {
    name: 'about',
    components: {
      HeaderIntroImage,
      ImageItem,
      BlockCarousel,
      MemberCardMin,
      GrayCard
    },
    props: {},
    data: () => {
      return {
        pageData: {
          attributes: {
            title: "",
            subtitle: "",
            tag: ""
          }
        },
        memberData: null,
        wgTitles: [],
        wgData: [],
        projectData: [],
        competenceMatches: [],
        resourceMatches: [],
        objectiveMatches: [],
        contribMaterial: [],
        contribPCT: []
      }
    },
    computed: {
      level() {
        if (this.memberData.attributes.field_experience_level == 1)
          return 'Early Years'
        if (this.memberData.attributes.field_experience_level == 2)
          return 'Mid-Career'
        if (this.memberData.attributes.field_experience_level == 3)
          return 'Advanced Career'
        else
          return null
      },
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      routesData() {
        return this.$store.state.routesData
      },
      objecttitle() {
        return this.$route.params.title
      },
      loggedUser() {
        return this.$store.getters.getLogged
      },
      memberUsername() {
        return this.memberData.uid.attributes.display_name
      },
      loggedIsMember() {
        return this.memberUsername == this.$store.state.logged_email
      },
      isPrivate() {
        return !this.loggedUser && this.memberData.attributes.field_member_data_visibility!='Full'
      },
      isVisible() {
        return this.loggedUser || (!this.loggedUser && this.memberData.attributes.field_member_data_visibility)
      },
      objectnid() {
        var objid = ''
        var link = this.routesData.filter(r => r.url_check == this.objecttitle)

        if(this.routesData.length>0 && link.length>0) {
          objid = link[0].nid
        }

        return objid
      },
      personphoto() {
        if (this.memberData && this.memberData.field_photo)
          return process.env.VUE_APP_ENDPOINT + this.memberData.field_photo.attributes.uri.url
        else return require('../../assets/profile.jpg')
      }
    },
    methods: {
      link(type, nid) {
        var l = []
        l = this.routesData.filter(r => r.nid == nid)
        if (l.length)
          return l[0].path
        else
          return ''
      },
      country(n) {
        return this.$store.getters.getCountry(this.memberData.field_organization[n].attributes.field_country.country)
      },
      addIncludedFieds(array, included, fieldNames) {
        array.map((item) => {
          //scorri i nomi dei campi esterni scelti
          fieldNames.forEach((fieldName) => {
            //se esiste quella relazione per l'oggetto corrente...
            if (item.relationships[fieldName] && item.relationships[fieldName].data) {
              //...e se i dati sono dentro un array (più di uno)
              if (Array.isArray(item.relationships[fieldName].data)) {
                //copia tutti i dati esterni che hanno la stessa foreign key di riferimento nell'oggetto corrente
                item[fieldName] = item.relationships[fieldName].data.map(
                  (relationshipItem) => {
                    return included.find((includedItem) => {
                      return includedItem.id === relationshipItem.id;
                    });
                  }
                );
                //...altrimenti copia il valore se ha la stessa foreign key
              } else if (item.relationships[fieldName].data.id) {
                item[fieldName] = included.find((includedItem) => {
                  return includedItem.id === item.relationships[fieldName].data.id;
                });
              }
            }
          })
        })

        return array
      },
      async findMember() {
        //console.log('collecting ...')
        await fetchNodes('member', {
            include: [
              'field_collaboration_objective', 'field_competence_offer', 'field_competences_looking_for',
              'field_organization', 'field_photo', 'field_programs_and_initiatives',
              'field_resource_looking_for', 'field_resource_offer', 'field_role_in_education_programs', 'uid'
            ],
            filters: [{
              key: 'drupal_internal__nid',
              value: this.objectnid
            }]
          }).then(res => {
            //console.log('member', res)
            this.memberData = res[0]
            this.memberData.field_programs_and_initiatives = this.memberData.field_programs_and_initiatives.filter(m => m != null)

            //console.log('isVisible', this.isVisible)
            //console.log('isPrivate', this.isPrivate)

            if(this.isVisible && this.isPrivate)
              this.$router.push('/private-profile')
            else if(!this.isVisible) 
              this.$router.push('/private-profile') //not-found or /private-profile ??
            else if(this.isVisible && !this.isPrivate)
              this.findData()

          })
      },
      async findData() {
        //FETCH PROJECTS WHERE MEMBER IS MEMBER OR COORDINATOR
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/pilot_project' +
            '?filter[projects_group][group][conjunction]=OR' +

            '&filter[coord_pj][condition][path]=field_pilot_project_coordinator.drupal_internal__nid' +
            '&filter[coord_pj][condition][operator]=IN' +
            '&filter[coord_pj][condition][value]=' + this.memberData.attributes.drupal_internal__nid +
            '&filter[coord_pj][condition][memberOf]=projects_group' +

            '&filter[member_pj][condition][path]=field_ref_member.drupal_internal__nid' +
            '&filter[member_pj][condition][operator]=IN' +
            '&filter[member_pj][condition][value]=' + this.memberData.attributes.drupal_internal__nid +
            '&filter[member_pj][condition][memberOf]=projects_group' +

            '&include=field_pilot_project_partner'
          )
          .then(res => res.json())
          .then(json => {
            this.projectData = json.data
          })

        var temp = []
        //FETCH MATERIALS WHERE IS CONTRIBUTOR or CONTACT PERSON
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/material' +
            '?filter[contrib-material][condition][path]=field_ref_member_2.drupal_internal__nid' +
            '&filter[contrib-material][condition][operator]=IN' +
            '&filter[contrib-material][condition][value]=' + this.memberData.attributes.drupal_internal__nid
          )
          .then(res => res.json())
          .then(json => {
            //console.log('contribution material', json.data)
            temp = json.data
          })

        //FETCH MEMBERS WHERE IS CONTRIBUTOR or CONTACT PERSON
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/program' +
            '?filter[contrib-program][condition][path]=field_ref_member_2.drupal_internal__nid' +
            '&filter[contrib-program][condition][operator]=IN' +
            '&filter[contrib-program][condition][value]=' + this.memberData.attributes.drupal_internal__nid
          )
          .then(res => res.json())
          .then(json => {
            //console.log('contribution pct', json.data)
            this.contribPCT = json.data
            this.contribMaterial = temp
          })

        //FETCH WORKING GROUPS WHERE MEMBER IS COORDINATOR
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/working_group' +
            '?filter[coord-wg][condition][path]=field_ref_coordinator.drupal_internal__nid' +
            '&filter[coord-wg][condition][operator]=IN' +
            '&filter[coord-wg][condition][value]=' + this.memberData.attributes.drupal_internal__nid
          )
          .then(res => res.json())
          .then(json => {
            json.data.forEach(p => {
              if (!this.wgTitles.includes(p.attributes.title)) {
                this.wgTitles.push(p.attributes.title)
                this.wgData.push(p)
              }
            })
            //console.log('coord in wgs', this.wgTitles, this.wgData)
          })

        //FETCH WORKING GROUPS WHERE MEMBER IS MEMBER
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/working_group' +
            '?filter[member-wg][condition][path]=field_ref_member.drupal_internal__nid' +
            '&filter[member-wg][condition][operator]=IN' +
            '&filter[member-wg][condition][value]=' + this.memberData.attributes.drupal_internal__nid
          )
          .then(res => res.json())
          .then(json => {
            json.data.forEach(p => {
              if (!this.wgTitles.includes(p.attributes.title)) {
                this.wgTitles.push(p.attributes.title)
                this.wgData.push(p)
              }
            })
            //console.log('member in wgs', this.wgTitles, this.wgData)
          })

        //FETCH WORKING GROUPS WHERE MEMBER IS ASSISTANT
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/working_group' +
            '?filter[assist-wg][condition][path]=field_ref_assistant.drupal_internal__nid' +
            '&filter[assist-wg][condition][operator]=IN' +
            '&filter[assist-wg][condition][value]=' + this.memberData.attributes.drupal_internal__nid
          )
          .then(res => res.json())
          .then(json => {
            json.data.forEach(p => {
              if (!this.wgTitles.includes(p.attributes.title)) {
                this.wgTitles.push(p.attributes.title)
                this.wgData.push(p)
              }
            })
            //console.log('assist in wgs', this.wgTitles, this.wgData)
          })

        //FETCH MEMBERS MATCHED BY COMPETENCES
        var url = ''
        this.memberData.field_competences_looking_for.forEach(c => url += (
          '&filter[competence-match][condition][value][]=' + c.attributes.name))
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/member' +
            '?filter[match-id][condition][path]=drupal_internal__nid' +
            '&filter[match-id][condition][operator]=<>' +
            '&filter[match-id][condition][value]=' + this.memberData.attributes.drupal_internal__nid +

            '&filter[competence-match][condition][path]=field_competence_offer.name' +
            '&filter[competence-match][condition][operator]=IN' +
            url +
            '&include=field_photo,field_organization'
          )
          .then(res => res.json())
          .then(json => {
            this.competenceMatches = shuffleArray(this.addIncludedFieds(json.data, json.included, ['field_photo',
              'field_organization'
            ])).slice(0, 5)
          })

        //FETCH MEMBERS MATCHED BY RESOURCES
        url = ''
        this.memberData.field_resource_looking_for.forEach(c => url += (
          '&filter[resource-match][condition][value][]=' + c.attributes.name))
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/member' +
            '?filter[match-id][condition][path]=drupal_internal__nid' +
            '&filter[match-id][condition][operator]=<>' +
            '&filter[match-id][condition][value]=' + this.memberData.attributes.drupal_internal__nid +

            '&filter[resource-match][condition][path]=field_resource_offer.name' +
            '&filter[resource-match][condition][operator]=IN' +
            url +
            '&include=field_photo,field_organization'
          )
          .then(res => res.json())
          .then(json => {
            this.resourceMatches = shuffleArray(this.addIncludedFieds(json.data, json.included, ['field_photo',
              'field_organization'
            ])).slice(0, 5)
          })

        //FETCH MEMBERS MATCHED BY OBJECTIVES
        url = ''
        this.memberData.field_collaboration_objective.forEach(c => url += (
          '&filter[objective-match][condition][value][]=' + c.attributes.name))
        await fetch(process.env.VUE_APP_ENDPOINT + 'jsonapi/node/member' +
            '?filter[match-id][condition][path]=drupal_internal__nid' +
            '&filter[match-id][condition][operator]=<>' +
            '&filter[match-id][condition][value]=' + this.memberData.attributes.drupal_internal__nid +

            '&filter[objective-match][condition][path]=field_collaboration_objective.name' +
            '&filter[objective-match][condition][operator]=IN' +
            url +
            '&include=field_photo,field_organization'
          )
          .then(res => res.json())
          .then(json => {
            this.objectiveMatches = shuffleArray(this.addIncludedFieds(json.data, json.included, ['field_photo',
              'field_organization'
            ])).slice(0, 5)
          })
      },
      wglink(wg) {
        var link = '/working-group'
        switch (wg.attributes.drupal_internal__nid) {
          case 14:
            link += '/school-education-and-public-outreach';
            break;
          case 15:
            link += '/educational-initiatives-higher-education';
            break;
          case 16:
            link += '/lifelong-learning-and-workforce-training';
            break;
          case 17:
            link += '/educational-research-quantum-technology';
            break;
          case 18:
            link += '/equity-and-inclusion-qt-educational-initiatives';
            break;
          default:
            link = '/not-found';
            break;
        }
        return link
      },
      projectlink(pj) {
        var link = this.routesData.filter(r => r.nid == pj.attributes.drupal_internal__nid)
        if (link.length > 0)
          return link[0].path
        else return '/not-found'
      },
      collectData() {
        this.wgData = []
        this.projectData = []
        this.competenceMatches = []
        this.resourceMatches = []
        this.objectiveMatches = []

        if(this.routesData.length>0)
          if(this.objectnid!='')
            this.findMember()
          else  
            this.$router.push('/not-found')
      }
    },
    async mounted() {
      this.collectData()
    },
    watch: {
      routesData() {
        this.collectData()
      },
      loggedUser() {
        this.collectData()
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.name-col {
  position: relative;
}

.awards {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  div {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:after {
      content: '';
      color: #ffba00;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      position: absolute;
      
      left: 50%;
      transform: translateX(-50%);

      //right: 50%;
      //transform: rotateZ(-60deg);
      //transform-origin: top right;
    }
  }

  .projects:hover:after {
    content: 'project member';
    bottom: -75%; 
  }

  .contribs:hover:after {
    content: 'contributor';
    bottom: -50%; 
  }
}

.grid-member {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  //padding: 3em 0 0 0;

  position: relative;
  top: -2em;
  
  /* .col1, .col2_1, .col3 { grid-column: span 1; }
  .col3 { grid-row-start: 2; } */
  .col1, .col2_1, .col3 { display: none; }
}

.flex-member {
  display: flex;
  flex-direction: column;
  //margin-bottom: 2em;
  position: relative;
  top: -6em;
  .member--thumbnail { margin-bottom: 1em; }
}

p.pink {
  background: $gradient;
  font-weight: 700;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  width: fit-content;
  margin: 0;
}

.name {
  margin-top: 0;
  grid-row-start: 1;
}

.expertise {
  margin: .5em 0;
  p { margin: 0; }

  &:before {
    width: 1.25em;
    height: 100%;
  }

  .label-2 {
    font-size: .9rem;
    line-height: 1em;
  }
}

.job {
  margin-bottom: .5em;
  p { margin: 0; }
  &:before {
    top: .25em;
    transform: none;
  }
  .label { margin-right: 5px; }
}

.from { margin-bottom: 0; }

.grid-member .col1 {
  position: relative;
  top: -10em;
  margin-bottom: -10em;
}

.member--thumbnail {
  width: 100%;
  //min-height: 15vw;
  min-height: 10vw;
  margin-bottom: 0;

  ::v-deep picture {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    img {
      max-width: 300px;
      max-height: 300px;
      object-fit: cover;
      border-radius: 50%;
      border: 4px #fff solid;
    }
  }
}

.gray-tiles {
  //margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;

  > div {
    background-color: $lightGray;
    padding: 1em 1em 3em 1em;

    a {
      width: 100%;
      &:before {
        top: 0;
        transform: none;
      }
    }
  }
}

.pilots {
  .label:before {
    top: .25em;
    transform: none;
  }
}

.wps {
  div {
    margin: 2em 0;
    &:hover { a { text-decoration: underline; } }
    
    a {
      padding-left: .5em;
      text-decoration: none;
    }
  }
}

.pilots {
  div {
    margin: 1em 0;
    &:hover { a { text-decoration: underline; } }

    a {
      color: #333;
      padding-left: .5em;
      text-decoration: none;
    }
  }
}

.objective {
  margin: .5em 0 1.5em 0;
  padding: 0 0 1em 1em;
  border-bottom: 2px dashed darken($hoverGray, 10%);

  .col2,
  .col1 {
    ::v-deep > p { margin: .5em 0; }
  }

  .icon-left:before {
    top: .25em;
    transform: none;
  }
}

.blue-bg .objective .external > a { line-height: 1.5em; }

.contacts-aside {
  box-shadow: 5px 5px 1em rgba(#000, .2);
  padding: 1em 2em 1em 2em;
  position: relative;
  top: 0;
  margin-bottom: 0;
  border-radius: 5px;
  background-color: #fff;

  .social-links {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    top: -1em;
    right: .5em;

    a {
      height: 2.5em;
      width: 2.5em;
      margin: 0;
      margin-left: .5em;
      background-color: #fff;
      border-radius: 50%;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.page-layout { padding-bottom: 0; }

.contribution {
  display: block;
  height: 100%;
  text-decoration: none;

  .gray-card {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 1em 2em;

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 7px;
      height: 100%;
      background: $vgradient;
    }
  }

  p { margin: .5em 0; }

  &:hover {
    .gray-card { background-color: rgba($primaryColor, .1); }
  }
}

@media (min-width: 1024px) {
  .grid-member {
    grid-template-columns: 1fr 3fr 3fr 1fr;
    gap: 20px;
    /* .col2_1 {
      grid-column: span 2;
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 1;
    } */
    .col2_2 {
      grid-column: span 2;
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 2;
    }
    .col1 { width: 220px; display: block; }
    /* .col1 { top: -7em; }
    .col3 { grid-row-start: unset; } */
  }

  .flex-member {
    display: grid;
    top: 0;
    grid-template-columns: 1fr 4fr 3fr;
    gap: 40px;
    margin-bottom: 2em;
    .col2_1 { margin-top: 1em; }
    .member--thumbnail { 
      top: -4em;
      position: relative;
    }
    .name { 
      padding-right: 1.5em;
    }
  }

  .member--thumbnail {
    width: 100%;

    picture {
      width: 220px;
      height: 220px;
    }
  }

  .wps > div { margin: 1em 0; }

  .contacts-aside {
    //top: -6em;
    > p { font-size: 14px; }
  }

  .objective {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .col2,
    .col1 {
      ::v-deep > p { margin: 0 0 .5em 0; }
    }
    .col1 { width: 39%; }
    .col2 { width: 59%; }
  }

  .blue-bg .objective {
    border: none;
    .icon-left { padding-left: 3em; }
    .col1 { width: 29%; }
    .col2 { width: 69%; }
  }

  .contacts-aside .social-links a {
    height: 2em;
    width: 2em;
  }

  .contributions.container {
    margin: 6rem auto;
    h2 { margin-top: 0; }

    ::v-deep {
      .slick-slide {
        height: 100%;
        .gray-card {
          width: 96%;
          margin: 0px auto;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .gray-tiles { grid-template-columns: 1fr 1fr; }
  .flex-member { grid-template-columns: 1fr 4.5fr 2.5fr; }
}
</style>