//formattazione dei nomi dei specifici content type
export function slugify(string) {
  return string
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[-]+/g, "-")
    .replace(/[^\w-]+/g, "");
}

export function convertMonth(num) {
  switch(num+1) {
    case 1: return 'January';
    case 2: return 'February';
    case 3: return 'March';
    case 4: return 'April';
    case 5: return 'May';
    case 6: return 'June';
    case 7: return 'July';
    case 8: return 'August';
    case 9: return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '-';
  }
}

export function reWriteDate(date) {
  // formato di entrata: stringa (e.g. 2021-06-04)
  
  let y = date.substr(0, 4);
  let m = date.substr(5,2);
  let d = date.substr(8, 11);
  
  // fotmato di uscita -> 04/06/2021
  return d + "/" + m + "/" + y;
}

// manage wysiwyg images
// rewrite path dell'immagine messa in wysiwyg
export function manageImages(image){

  let text = image
  let fixedUrl = text.replace("/sites/default/files/inline-images/", process.env.VUE_APP_ENDPOINT + "/sites/default/files/inline-images/")
  return fixedUrl
}
export function shuffleArray(array) {
  var currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

/*export function fieldColorExpert(field) {
  var c = ''
  switch(field) {
      case 1: c='#F3B185'; break;
      case 2: c='#BFD4E4'; break;
      case 3: c='#CFC5D4'; break;
      case 4: c='#C3C6C5'; break;
      case 5: c='#E0D2B9'; break;
      case 6: c='#8CBDC1'; break;
      case 7: c='#DCD0C6'; break;
      default: c='$darkGray999'; break;
  }
  return c
}

export function fieldColorProject(field) {
  var c = ''
  switch(field) {
      case 9: c='#D91B5C'; break;
      case 10: c='#009999'; break;
      case 8: c='#D9C89E'; break;
      default: c='$darkGray999'; break;
  }
  return c
}

export function srcImage(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./' + image)
}

export function fieldSrcDarkExpert(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-dark-' + image + '.svg')
}
export function fieldSrcDarkProject(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-project-dark-' + image + '.svg')
}
export function fieldSrcExpert(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-' + image + '.svg')
}
export function fieldSrcProject(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-project-' + image + '.svg')
}
*/